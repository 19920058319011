import React, { useEffect, useState } from 'react'
import { Media } from 'reactstrap'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import emailjs from 'emailjs-com'
import logo1 from "../assets/images/Dharashiv_Logo.png?v1=1"
import logo2 from "../assets/images/Dharashiv_Banner.png?v=1"

function Home() {

	useEffect(() => { window.scrollTo(0, 0) }, []);

	const now = Date.now()
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [successMessage, setSuccessMessage] = useState('')
	const mobileRegex = /^[6-9]\d{9}$/;
	const numberRegex = /^\d+$/;
	const sendEmail = async (data) => {
		let templateParams = {
			subject: `${data.subject} From ${data.name}`,
			from_name: `${data.firstName} ${data.lastName}`,
			to_name: 'Mr. Admin',
			message: `Email Id: ${data.email}, Mobile Number: ${data.mobile}, Bid Amount: ${data.bid}`,
		}
		console.log('In send email')
		let r = await emailjs.send('service_g1rlqr8', 'template_jsuf2uk', templateParams, 'nWF0__97tnrStdZHd')
		console.log(r)
	}

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			bid: '',
			// country: 'India'
		},
		validationSchema: Yup.object({
			firstName: Yup.string().min(3, 'Ohh.. That\'s not the First Name !').required('Please Provide Us Your Good Name.'),
			lastName: Yup.string().min(3, 'Ohh.. That\'s not the Last Name !').required('Please Provide Us Your Good Name.'),
			email: Yup.string().email('That\'s not the valid Email.').required('Please Provide Us Your E-Mail Address.'),
			mobile: Yup.string().matches(mobileRegex, 'Mobile number is not valid').required('Please Provide Us Your Mobile Number.'),
			bid: Yup.string().matches(numberRegex, 'Offer amount is not valid').required('Please Provide Us Your Offer Amount.'),
			// country: Yup.string().min(10, 'Please Select Country').required('Please Select Country, You should be Indian.'),
		}),
		onSubmit: async (values) => {
			console.log('On Submit')
			setErrorMessage('')
			setLoading(true)
			try {
				await sendEmail(values)
				setLoading(false)
				setSuccessMessage('Thank You! We have received your offer, We will get back to you very soon!')
			} catch (error) {
				console.log(error)
				setErrorMessage(error.message)
				setLoading(false)
			}
		}
	})


	return (
		<>
			<div className="container">
				<div className="row equal" style={{ zIndex: 999 }}>
					<div className='col-lg-12 text-center'>
						<strong><h1 className="headingNeonText text-center site-main-heading">धाराशिव.कॉम वर आपले स्वागत आहे</h1></strong>
					</div>
				</div>
				<div className="row equal">
					<div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
						<div className="signup-form">
							<form>
								<a className="bd-logo-main" href="#">
									<img src={logo1} title="Dharashiv Osmanabad Maharashtra" alt="Dharashiv Osmanabad Maharashtra" className="img-responsive" />
								</a>
								<h3 className="neonText text-center" style={{ color: '#c81231', fontSize: '3.0rem' }}>Is currently for SALE !</h3>
								<div className="form-group">
									<a href="mailto:dharashivdotcom@gmail.com">
										<button type="button" className="btn btn-success btn-lg btn-block">
											Email us on DharashivDotCom@gmail.com
										</button>
									</a>
								</div>
								<p>
									We can give you the price instantly over the email, guide you through the buying process, and
									answer any questions you may have.
								</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> Dharashiv is not just a name, It is a proud and soul of all of us.</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> Dharashiv.com The Name Everyone Wish to own</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> Be The #1 Indian who own Dharashiv.com.</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> Dharashiv.com The Brand.</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> Instant Payment processing.</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> You can own this domain in less than 72 hours.</p>
								<p><i className="fa fa-check" aria-hidden="true"></i> You are just a click away to be a owner of Dharashiv.com.</p>
							</form>
							<br />
						</div>
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
						<div className="signup-form">
							<Form id="contact" action="" method="post" onSubmit={formik.handleSubmit}>
								<h2>Get a price in less than 24 hours</h2>
								<p className="hint-text">One of our domain experts will respond to you within 24 business hours.</p>
								<div className="form-group">
									<div className="row">
										<div className="col-xs-6">
											<fieldset>
												{
													(formik.errors.firstName && formik.touched.firstName) &&
													<p style={{ color: 'red', marginLeft: 15 }}>{formik.errors.firstName}</p>
												}
												<Form.Control
													type="text"
													name='firstName'
													id='firstName'
													placeholder="First Name"
													value={formik.values.firstName}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													className="form-control"
												/>
											</fieldset>
										</div>
										<div className="col-xs-6">
											<fieldset>
												{
													(formik.errors.lastName && formik.touched.lastName) &&
													<p style={{ color: 'red', marginLeft: 15 }}>{formik.errors.lastName}</p>
												}
												<Form.Control
													type="text"
													name='lastName'
													id='lastName'
													placeholder="Last Name"
													value={formik.values.lastName}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													className="form-control"
												/>
											</fieldset>
										</div>
									</div>
								</div>
								<div className="form-group">
									<fieldset>
										{
											(formik.errors.email && formik.touched.email) &&
											<p style={{ color: 'red', marginLeft: 15 }}>{formik.errors.email}</p>
										}
										<Form.Control
											type='text'
											name='email'
											id='email'
											placeholder='E-Mail Address'
											value={formik.values.email}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											className="form-control"
										/>
									</fieldset>
								</div>
								<div className="form-group">
									<fieldset>
										{
											(formik.errors.mobile && formik.touched.mobile) &&
											<p style={{ color: 'red', marginLeft: 15 }}>{formik.errors.mobile}</p>
										}
										<Form.Control
											type='text'
											name='mobile'
											id='mobile'
											placeholder='Mobile Number'
											value={formik.values.mobile}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											className="form-control"
										/>
									</fieldset>
								</div>
								<div className="form-group">
									<div className="row">
										<div className="col-xs-6">
											<fieldset>
												{
													(formik.errors.bid && formik.touched.bid) &&
													<p style={{ color: 'red', marginLeft: 15 }}>{formik.errors.bid}</p>
												}
												<Form.Control
													type='text'
													name='bid'
													id='bid'
													placeholder='Your Offer (INR)'
													value={formik.values.bid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													className="form-control"
												/>
											</fieldset>
										</div>
										<div className="col-xs-6">
											<fieldset>
												<Form.Control
													as='select'
													name='country'
													id='country'
													value={formik.values.country}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													className="form-control"
												>
													<option value="India">India</option>
												</Form.Control>
											</fieldset>
										</div>
									</div>
								</div>
								<div className="form-group">
									<label>By clicking "Send Offer" you agree to our <a href="#">privacy policy</a>.</label>
								</div>
								<div className="form-group text-center">
									<fieldset>
										{
											loading &&
											<Spinner animation="border" style={{ color: '#FFF' }} />
										}
										{!loading &&
											<Button
												type="submit"
												id="form-submit"
												className="btn btn-success btn-lg btn-block"
												disabled={!(formik.isValid && formik.dirty)}
											>
												Send Offer
											</Button>
										}
									</fieldset>
									<br />
									<br />
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row equal">
					<div className='col-lg-12 text-center'>
						<strong><h1>You can also visit following websites</h1></strong>
					</div>
				</div>
				<div className="row equal text-center">
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivkar.com" target="_blank" variant="link" className='btn-website-link'>DharashivKar.com</Button>
						</div>
					</div>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivkar.in" target="_blank" variant="link" className='btn-website-link'>DharashivKar.in</Button>
						</div>
					</div>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivmajha.com" target="_blank" variant="link" className='btn-website-link'>DharashivMajha.com</Button>
						</div>
					</div>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivmajha.in" target="_blank" variant="link" className='btn-website-link'>DharashivMajha.in</Button>
						</div>
					</div>
				</div>
				<div className="row equal text-center" style={{ marginTop: 10 }}>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivkar.com" target="_blank" variant="link" className='btn-website-link'>धाराशिवकर.कॉम</Button>
						</div>
					</div>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivkar.in" target="_blank" variant="link" className='btn-website-link'>धाराशिवकर.इन</Button>
						</div>
					</div>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivmajha.com" target="_blank" variant="link" className='btn-website-link'>धाराशिवमाझा.कॉम</Button>
						</div>
					</div>
					<div className='col-lg-3 col-sm-6 col-xs-12'>
						<div class="wrapper">
							<Button href="http://dharashivmajha.in" target="_blank" variant="link" className='btn-website-link'>धाराशिवमाझा.इन</Button>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div className="container">
				<footer className="row">
					<div className="col-12 font-weight-light text-center">
						<p className="d-inline-block tm-bg-black text-info py-2 px-4">
							Copyright © 2022. <a href="http://dharashiv.com">Dharashiv.com</a>
						</p>
					</div>
				</footer>
			</div>
		</>
	)
}

export default Home;
