import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MainLayout from './components/layout/MainLayout'
import Home from './pages/Home'

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`
}

function App() {
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {
      setShowAnimation(false);
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (showAnimation) {
    return (
      <div id="preloader">
        <div className="jumper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }

  return (
    <BrowserRouter basename={getBasename()}>
      <Switch>
        <MainLayout>
          <Route exact path='/' component={Home} />
        </MainLayout>
      </Switch>
    </BrowserRouter>
  )
}

export default App
