import React from 'react';

function MainLayout(props) {

  const { children } = props;

  return (
    <>
      <div className="fixed-top"></div>
      <div style={{ marginTop: '80px' }}>{children}</div>
    </>
  );
}

export default MainLayout;